import * as React from 'react';
import ContactWrapper from '../components/cta/ContactWrapper';
import AppContainer from '../components/Layout/AppContainer';
import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section';

class Contact extends React.Component {
    private CoverContent() {
        return <></>;
    }

    public render() {
        return (
            <AppContainer>
                <Layout cover={<this.CoverContent />}>
                    <Section>
                        <ContactWrapper />
                    </Section>
                </Layout>
            </AppContainer>
        );
    }
}

export default Contact;
